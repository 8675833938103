import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import {SlideOver} from "../components/SlideOver";
import {UserForm, UserValues} from "../components/UserForm";
import {useTranslation} from "react-i18next";
import { useState } from 'react';

interface UserCreateRouteProps {
	companyId: string;
}

const UsersCreateRoute: React.FC<UserCreateRouteProps> = ({ companyId }) => {
	const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)

  const { data, loading, error } = useQuery(gql`
    query UsersCreateRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        departments {
          id
          name
        }
      }
    }
  `, {
    variables: {
      companyId
    }
  });

  const [createUser] = useMutation(gql`
    mutation UsersCreateRoute_CreateUserMutation($input: CreatUserMutationInput!) {
      createUser(input: $input) {
        user {
          id
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'UsersOverviewRouteQuery',
    ]
  })

  function onCancel() {
			navigate(`/${companyId}/users`)
  }
  async function onSubmit(values: UserValues) {
		let { data } = await createUser({ variables: { input: { companyId, ...values } } })
    setMutationErrors(data.createUser.errors)

    if (data.createUser.errors.length == 0) {
		  navigate({pathname: `/${companyId}/users`}, {replace: true})
    }
  }

	return (
		<SlideOver title={t('create_user')} onClose={onCancel}>
			<UserForm
				onCancel={onCancel}
				onSubmit={onSubmit}
			/>
      {mutationErrors && mutationErrors.length > 0 &&
        <div className="bottom-10 px-5 mt-4 py-4 bg-red-500 bg-white drop-shadow-lg">
          <div className="text-sm text-white">
            <span className="mr-4 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
            {mutationErrors.join(', ')}
          </div>
        </div>
      }
		</SlideOver>
	)
}

export default UsersCreateRoute;
