import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { SettingsRouteQuery } from '../__generated__/SettingsRouteQuery';
import { CompanyChannelsForm } from '../components/CompanyChannelsForm';
import { CompanyDetailsForm } from '../components/CompanyDetailsForm';
import { DialogFlowForm } from '../components/DialogFlowForm';
import {useTranslation} from "react-i18next";

interface SettingsRouteProps {
  companyId: string;
  userRole: string
}

const SettingsRoute: React.VFC<SettingsRouteProps> = ({ userRole, companyId }) => {
  const { t, i18n } = useTranslation();
  const { data, loading, error } = useQuery<SettingsRouteQuery>(
    gql`
      query SettingsRouteQuery($id: ID!) {
        company(id: $id) {
          id

          ...CompanyChannelsForm_company
          ...CompanyDetailsForm_company
          ...DialogFlowForm_company
        }

        whatsappProviders {
          ...CompanyChannelsForm_whatsappProviders
        }
      }

      ${CompanyChannelsForm.fragments.company}
      ${CompanyChannelsForm.fragments.whatsappProviders}
      ${CompanyDetailsForm.fragments.company}
      ${DialogFlowForm.fragments.company}
    `,
    {
      variables: { id: companyId },
    }
  )

  return (
    <div>
      <header>
        <div className="flex items-center justify-between h-10">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            {t('settings')}
          </h1>
        </div>
      </header>

      <main className="space-y-8 mt-8">
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('company_details')}
            </h3>
          </div>
          <div className="col-span-2">
            {loading ? (
              <p>{t('loading')}</p>
            ) : error ? (
              <p>{t('error')}</p>
            ) : !data!.company ? (
              <p>{t('not_found')}</p>
            ) : (
              <CompanyDetailsForm company={data!.company} />
            )}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('channels')}
            </h3>
          </div>
          <div className="col-span-2">
            {loading ? (
              <p>{t('loading')}</p>
            ) : error ? (
              <p>{t('error')}</p>
            ) : !data!.company ? (
              <p>{t('not_found')}</p>
            ) : (
              <CompanyChannelsForm company={data!.company} whatsappProviders={data!.whatsappProviders} />
            )}
          </div>
        </div>

        {userRole == 'superadmin' &&
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t('dialogflow')}
              </h3>
            </div>
            <div className="col-span-2">
              {loading ? (
                <p>{t('loading')}</p>
              ) : error ? (
                <p>{t('error')}</p>
              ) : !data!.company ? (
                <p>{t('not_found')}</p>
              ) : (
                <DialogFlowForm company={data!.company} />
              )}
            </div>
          </div>
        }
      </main>
    </div>
  )
}

export default SettingsRoute;
