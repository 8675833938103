import * as React from 'react'
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption, ComboboxOptionText } from "./Combobox";
import { matchSorter } from "match-sorter";
import { TextInput } from './text-input-field';
import useClickOutside from '../hooks/useClickOutside';
import useThrottle from '../hooks/useThrottle';
import {useTranslation} from "react-i18next";
import i18n from "i18next";

export interface Option {
  id: string
  name: string
}

export interface SearchBoxProps {
  className?: string
  options: Option[]
  disabled?: boolean
  maxResults?: number
  placeholder?: string
  onSelect?: (id: string) => void
}

export const SearchBox: React.VFC<SearchBoxProps> = ({ className, disabled, maxResults = 10, options, placeholder = i18n.t('search_by_name_or_tag'), onSelect: onSelectProp }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [term, setTerm] = React.useState<string>("");
  const results = useMatchTerm(options, term);
  const { t, i18n } = useTranslation();

  console.log(results)
  useClickOutside(ref, () => {
    if (term !== '') {
      setTerm('')
    }
  })

  function onInputChange (event: React.ChangeEvent<HTMLInputElement>) {
    setTerm(event.target.value);
  }

  function onSelect(_value: string, result: Option) {
    setTerm('')

    if (onSelectProp) {
      onSelectProp(result.id);
    }
  }

  return (
    <Combobox ref={ref} className={className} onSelect={onSelect} openOnFocus={true}>
      <ComboboxInput
        as={TextInput}
        disabled={disabled}
        placeholder={placeholder}
        value={term}
        onChange={onInputChange}
      />
      {results && (
        <ComboboxPopover>
          {results.length > 0 ? (
            <ComboboxList>
              {results.slice(0, maxResults).map((result, index) => (
                <ComboboxOption
                  key={index}
                  value={result.name}
                  selectData={result}
                >
                  <span className="block truncate">
                    <ComboboxOptionText />
                  </span>
                </ComboboxOption>
              ))}
            </ComboboxList>
          ) : (
            <span className="block text-gray-700 py-2 pl-3 pr-9">
             {t('not_found')}
            </span>
          )}
        </ComboboxPopover>
      )}
    </Combobox>
  )
}

function useMatchTerm(options: Option[], term: string) {
  const throttledTerm = useThrottle(term, 100);

  const key = options && options.length > 0 && options[0]['nameTags'] ? 'nameTags' : 'name'
  console.log(key)
  return React.useMemo(
    () =>
      term.trim() === ""
        ? options
        : matchSorter(options, term, { keys: [key] }),
    [throttledTerm, options]
  );
}
