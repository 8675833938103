export default function PolicyCan(userRole: string | null | undefined, entity: any, action: string) : boolean{
  if (!userRole) {
    return false;
  }

  if (userRole == 'superadmin') {
    return true;
  }

  switch(entity) {
    case 'departments':
      switch(action) {
        case 'index':
          return ['admin', 'admin_plus'].includes(userRole)
        case 'new':
          return ['admin', 'admin_plus'].includes(userRole)
        case 'edit':
          return ['admin', 'admin_plus'].includes(userRole)
        case 'edit_shop':
          return ['admin_plus'].includes(userRole)
        case 'destroy':
          return ['admin', 'admin_plus'].includes(userRole)
        default:
          return false;
      }
    case 'bot_modules':
      switch(action) {
        case 'index':
          return ['admin', 'admin_plus', 'content_creator'].includes(userRole)
        case 'new':
          return ['admin', 'admin_plus', 'content_creator'].includes(userRole);
        case 'edit':
          return ['admin', 'admin_plus', 'content_creator'].includes(userRole);
        case 'destroy':
          return ['admin', 'admin_plus', 'content_creator'].includes(userRole);
        default:
          return false;
      }
      case 'bot_users':
        switch(action) {
          case 'index':
            return ['admin', 'admin_plus'].includes(userRole)
          case 'export':
            return ['admin', 'admin_plus', 'supervisor'].includes(userRole)
          case 'new':
            return ['admin', 'admin_plus'].includes(userRole);
          case 'edit':
            return ['admin', 'admin_plus'].includes(userRole);
          case 'destroy':
            return ['admin', 'admin_plus'].includes(userRole);
          default:
            return false;
        }
      case 'users':
        switch(action) {
          default:
            return false;
        }
      case 'orders':
        switch(action) {
          case 'index':
            return ['admin_plus'].includes(userRole)
          default:
            return false;
        }
      case 'licenses':
        switch(action) {
          case 'index':
            return ['admin', 'admin_plus'].includes(userRole)
          case 'new':
            return ['admin', 'admin_plus'].includes(userRole);
          case 'edit':
            return ['admin', 'admin_plus'].includes(userRole);
          case 'destroy':
            return ['admin', 'admin_plus'].includes(userRole);
          default:
            return false;
        }
      case 'signals':
        switch(action) {
          case 'index':
            return ['admin', 'admin_plus'].includes(userRole)
          case 'new':
            return ['admin', 'admin_plus'].includes(userRole);
          case 'edit':
            return ['admin', 'admin_plus'].includes(userRole);
          case 'destroy':
            return ['admin', 'admin_plus'].includes(userRole);
          default:
            return false;
        }
      case 'insight':
        switch(action) {
          case 'index':
            return ['admin', 'admin_plus', 'supervisor'].includes(userRole)
          default:
            return false;
        }
      case 'settings':
        switch(action) {
          case 'index':
            return ['admin', 'admin_plus'].includes(userRole)
          default:
            return false;
        }
      default:
        return false;
  }

}
